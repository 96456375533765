import { useContext, useState } from "react";
import { returnAllActive } from "../util";
import PlayerCard from "../PlayerCard/PlayerCard";
import {getDocs, collection, query, where, updateDoc, doc} from "firebase/firestore"
import { db } from "../../db/firebase";
import Navbar from "../Navbar/Navbar";
import Pack from "./Pack/Pack";
import { AuthContext } from "../../context/AuthContext";

import "./Store.css"

function Store() {
    const [openedPlayers, setOpenedPlayers] = useState([]);

    const {currentUser, dispatch} = useContext(AuthContext);

    console.log('opened players: ', openedPlayers);

    function getRandomInt(min, max) {
        var range = max - min + 1;
        var byteArrayLength = Math.ceil(Math.log2(range) / 8); // Calculate the number of bytes needed based on the range
        
        var byteArray = new Uint8Array(byteArrayLength);
        window.crypto.getRandomValues(byteArray);
      
        var maxRange = Math.pow(256, byteArrayLength); // Calculate the maximum possible value from the generated bytes
        
        if (byteArray[0] >= Math.floor(maxRange / range) * range) {
          return getRandomInt(min, max); // If the generated value is outside the desired range, recursively call the function again
        }
        
        var randomValue = 0;
        for (var i = 0; i < byteArrayLength; i++) {
          randomValue += byteArray[i] * Math.pow(256, i); // Calculate the random value by combining the generated bytes
        }
        
        return min + (randomValue % range);
    }
          
    async function openPack(packLength) {
        const players = await getDocs(query(collection(db, "players"), where("collectionCard", "==", false)));
        var allPlayers = [];
        players.forEach(doc => {
            var player = doc.data();
            player.id = doc.id;
            allPlayers.push(player);
        })
        var tempUser = Object.assign({}, currentUser);
        var packedPlayers = [];
        for(var i = 0; i < packLength; i++) {
            let random = getRandomInt(0, allPlayers.length);
            packedPlayers.push(allPlayers[random]);
            tempUser.team.roster.push(allPlayers[random]);
        }

        console.log('packed players', packedPlayers);

        setOpenedPlayers(packedPlayers);
        return tempUser;
    }


    function returnRosterIds(roster) {
        var ids = [];
        for(let i = 0; i < roster.length; i++) {
            ids.push(roster[i].id);
        }
        return ids;
    }

    async function purchasePack(packPrice, packLength) {
        let pucks = currentUser.pucks;
        if(pucks < packPrice) return;
        var updatedUser = await openPack(packLength);
        pucks -= packPrice;
        updatedUser.pucks = pucks;
        let ids = [];
        for(let i = 0; i < updatedUser.team.roster.length; i++) {
            ids.push(updatedUser.team.roster[i].id)
        }
        var dbPayload = Object.assign({}, updatedUser);
        dbPayload.team = Object.assign({}, updatedUser.team);
        dbPayload.team.roster = ids;
        dbPayload.team.lineup = returnRosterIds(dbPayload.team.lineup);
        var ref = doc(db, 'users', currentUser.id);
        await updateDoc(ref, dbPayload);
        dispatch({type: "LOGIN", payload:updatedUser});
    }
    
    return(
        <div>
            <Navbar />
            <Pack purchasePack={purchasePack} price={500} size={5} name={'Standard Pack'} />
            <Pack purchasePack={purchasePack} price={500} size={100} name={'Jumbo Pack'} />

            {openedPlayers.length > 0 && <div className="openedPlayers">
                {openedPlayers.map(player => (
                    <div className="playerCardContainer">
                        <PlayerCard player={player} team={player.team} />
                    </div>
                ))}
            </div>}
        </div>
    )
}

export default Store;