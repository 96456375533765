import Navbar from "../Navbar/Navbar";
import Calendar from "../Calendar/Calendar"

function Play() {
    return(
    <div>
        <Navbar />
        <Calendar />
    </div>)
}

export default Play;